import { log, supabase } from ".";

export const updateCliente = async ({
  id,
  ejecutivo,
  agencia,
  ultimo_contacto_fecha,
  ultimo_contacto_accion,
  proximo_contacto_fecha,
  proximo_contacto_accion,
  contactoId,
}) => {
  try {
    const updatedData = {
      ejecutivo: ejecutivo || null,
      agencia: agencia || null,
      ultimo_contacto_fecha:
        ultimo_contacto_fecha instanceof Date ? ultimo_contacto_fecha.toISOString() : ultimo_contacto_fecha,
      ultimo_contacto_accion: ultimo_contacto_accion || null,
      proximo_contacto_fecha:
        proximo_contacto_fecha instanceof Date ? proximo_contacto_fecha.toISOString() : proximo_contacto_fecha,
      proximo_contacto_accion: proximo_contacto_accion || null,
      contactoId: contactoId || null,
    };

    // Llama a la función personalizada 'update'
    const { data, error } = await supabase.update("clientes", updatedData, id);

    // Manejo de errores
    if (error) {
      throw error;
    }

    return data;
  } catch (error) {
    log.error("updateCliente", error);
    throw error;
  }
};
