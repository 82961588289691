/* eslint-disable no-console */
import { useEffect, useState } from "react";
import { useAuthContext } from "../../context/auth";
import { getClientesPublicitarios, getIntermediarios, updateCliente, getUsuariosByPaisAndRol } from "../../utils";

export const ListaClientes = () => {
  const [clientes, setClientesPublicitarios] = useState([]);
  const [intermediarios, setIntermediarios] = useState([]);
  const [usuarios, setUsuarios] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editingClienteId, setEditingClienteId] = useState(null);

  const { pais, nombre, rol } = useAuthContext() || {};

  // Función para convertir el nombre del país a su código
  const getPaisCode = (countryName) => {
    switch (countryName.toLowerCase()) {
      case "Uruguay":
        return "URU";
      case "Chile":
        return "CHI";
      case "Perú":
        return "PER";
      default:
        return countryName; // Si no coincide, devolver el nombre original para no perder datos
    }
  };

  useEffect(() => {
    const initialize = async () => {
      try {
        const paisCode = getPaisCode(pais);
        console.log("Consultando clientes para el país:", paisCode);
        let newClientesPublicitarios = await getClientesPublicitarios({ pais: paisCode });
        console.log("Clientes recibidos:", newClientesPublicitarios);

        // Filtrar clientes para usuarios comerciales
        if (rol === "comercial") {
          newClientesPublicitarios = newClientesPublicitarios.filter((cliente) => cliente.ejecutivo === nombre);
        }

        const [filteredClientes, newIntermediarios, allUsuarios] = await Promise.all([
          Promise.resolve(newClientesPublicitarios),
          getIntermediarios({ pais: paisCode }),
          getUsuariosByPaisAndRol({ pais: paisCode }),
        ]);

        // Filtrar usuarios por rol y país en el componente
        const filteredUsuarios = allUsuarios.filter(
          (usuario) => ["comercial", "superadmin"].includes(usuario.rol) && ["CHI"].includes(usuario.pais)
        );

        setClientesPublicitarios(filteredClientes || []);
        setIntermediarios(newIntermediarios || []);
        setUsuarios(filteredUsuarios || []);
        setLoading(false);
      } catch (error) {
        console.error("Error al inicializar datos:", error);
        setLoading(false);
      }
    };

    initialize();
  }, [pais, nombre, rol]);

  if (loading) return <div>Cargando...</div>;

  const handleUpdateCliente = async (clienteId, newEjecutivo, newAgencia) => {
    try {
      await updateCliente({ id: clienteId, ejecutivo: newEjecutivo, agencia: newAgencia });

      // Actualizar estado local
      setClientesPublicitarios(
        clientes.map((cliente) =>
          cliente.id === clienteId ? { ...cliente, ejecutivo: newEjecutivo, agencia: newAgencia } : cliente
        )
      );
      setEditingClienteId(null); // Ocultar los dropdowns después de la actualización
    } catch (error) {
      console.error("Error updating cliente:", error.message);
    }
  };

  const renderWithStyle = (value) => {
    if (value === "No asignado") {
      return <span className="text-danger">{value.toUpperCase()}</span>;
    }
    return value;
  };

  return (
    <div className="container">
      <table className="table table-bordered table-hover table-striped">
        <thead className="table-dark">
          <tr>
            <th width="25%" className="text-center">
              Nombre
            </th>
            <th width="25%" className="text-center">
              Categoría
            </th>
            <th width="25%" className="text-center">
              Agencia
            </th>
            {rol === "superadmin" && (
              <th width="25%" className="text-center">
                Ejecutivo
              </th>
            )}
            <th width="25%" className="text-center">
              Acciones
            </th>
          </tr>
        </thead>
        <tbody>
          {Array.isArray(clientes) &&
            clientes.map((cliente, index) => (
              <tr key={index}>
                <td className="text-start">{cliente.nombre}</td>
                <td className="text-start">{cliente.categoria}</td>
                <td className="text-center">
                  {editingClienteId === cliente.id && (rol === "superadmin" || rol === "comercial") ? (
                    <select
                      value={cliente.agencia || ""}
                      onChange={(e) => handleUpdateCliente(cliente.id, cliente.ejecutivo, e.target.value)}
                    >
                      <option value="">Seleccionar</option>
                      {Array.isArray(intermediarios) &&
                        intermediarios.map((intermediario) => (
                          <option key={intermediario.id} value={intermediario.nombre}>
                            {intermediario.nombre}
                          </option>
                        ))}
                    </select>
                  ) : (
                    renderWithStyle(cliente.agencia || "No asignado")
                  )}
                </td>
                {rol === "superadmin" && (
                  <td className="text-center">
                    {editingClienteId === cliente.id ? (
                      <select
                        value={cliente.ejecutivo || ""}
                        onChange={(e) => handleUpdateCliente(cliente.id, e.target.value, cliente.agencia)}
                      >
                        <option value="">Seleccionar</option>
                        {Array.isArray(usuarios) &&
                          usuarios.map((usuario) => (
                            <option key={usuario.id} value={usuario.nombre}>
                              {usuario.nombre}
                            </option>
                          ))}
                      </select>
                    ) : (
                      renderWithStyle(cliente.ejecutivo || "No asignado")
                    )}
                  </td>
                )}
                <td className="text-center">
                  {rol === "superadmin" || rol === "comercial" ? (
                    editingClienteId === cliente.id ? (
                      <button className="btn btn-sm btn-outline-success" onClick={() => setEditingClienteId(null)}>
                        Guardar
                      </button>
                    ) : (
                      <button
                        className="btn btn-sm btn-outline-primary"
                        onClick={() => setEditingClienteId(cliente.id)}
                      >
                        Edit
                      </button>
                    )
                  ) : null}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};
