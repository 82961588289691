/* eslint-disable unicorn/prefer-at */
/* eslint-disable no-console */
import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from "reactstrap";
import cx from "classix";
import { Container, Loader } from "../../components";
import { useAuthContext } from "../../context/auth";
import { getCategorias, getIntermediarios, getUsers, log, quitarCaracteresEspeciales, saveCliente } from "../../utils";

export const CargarCliente = () => {
  const [categorias, setCategorias] = useState([]);
  const [intermediarios, setIntermediarios] = useState([]);
  const [showModalConfirmacion, setShowModalConfirmacion] = useState(false);
  const [temporalDataForm, setTemporalDataForm] = useState();
  const [usuarios, setUsuarios] = useState([]);
  const [isAuthLoaded, setIsAuthLoaded] = useState(false);

  const { pais } = useAuthContext();

  const paisMap = {
    Chile: "CHI",
    Uruguay: "URU",
    Perú: "PER",
  };

  const paisCode = paisMap[pais] || pais; // Usa pais sin mapeo si no está en el diccionario

  const {
    formState: { errors },
    handleSubmit,
    register,
    reset,
    setError,
  } = useForm();

  useEffect(() => {
    if (pais !== undefined) {
      setIsAuthLoaded(true);
    }
  }, [pais]);

  const onSubmit = useCallback(
    async (data) => {
      setShowModalConfirmacion(true);

      const newCliente = {
        nombre: quitarCaracteresEspeciales(data.nombre),
        agencia: data.agencia.toUpperCase(),
        categoria: data.categoria.toUpperCase(),
        ejecutivo: data.ejecutivo,
        lastupdate: new Date(),
        pais,
      };

      setTemporalDataForm(newCliente);
    },
    [pais]
  );

  const saveButtonHandle = useCallback(async () => {
    try {
      setShowModalConfirmacion(false);
      await saveCliente(temporalDataForm);
      toast.success("El cliente se guardó correctamente");
      reset();
    } catch (error) {
      if (error.code === "23505") setError("nombre", { type: "custom", message: "El cliente ya existe" });
      toast.error("Hubo un error al intentar guardar el nuevo cliente, prueba de nuevo.");
      log.error("saveButtonHandle", error);
    }
  }, [temporalDataForm, reset, setError]);

  const validarSelect = useCallback((value) => value !== "0", []);

  const disableSubmit = useMemo(() => showModalConfirmacion, [showModalConfirmacion]);

  useEffect(() => {
    if (pais !== undefined) {
      const initialize = async () => {
        const newIntermediarios = await getIntermediarios({ pais });
        setIntermediarios(newIntermediarios || []);

        const newCategorias = await getCategorias();
        setCategorias(newCategorias || []);

        const users = await getUsers({ pais });
        const filteredUsuarios = users.filter((user) => user.pais === paisCode && user.rol === "comercial");
        setUsuarios(filteredUsuarios);
      };

      initialize();
    }
  }, [pais, paisCode]);

  if (!isAuthLoaded) {
    return <Loader />;
  }

  if (intermediarios === undefined || categorias === undefined) {
    return <Loader />;
  }

  const validateLastChar = (value) => {
    // Remove leading/trailing spaces before checking the last character
    const trimmedValue = value.trim();
    const lastChar = trimmedValue.charAt(trimmedValue.length - 1);
    return /^[\dA-Za-z]$/.test(lastChar) || "El último carácter debe ser una letra o número.";
  };

  return (
    <>
      <Container className="CargarCliente" header titulo="CARGAR NUEVO CLIENTE">
        <form className="row g-3" onSubmit={handleSubmit(onSubmit)}>
          <div className="col-3">
            <label htmlFor="nombre" className="form-label">
              Nombre comercial
            </label>
            <input
              className={cx("form-control", errors?.nombre && "is-invalid")}
              id="nombre"
              placeholder="Ej.: LATAM AIRLINES, 25 caracteres máx."
              type="text"
              maxLength={25}
              {...register("nombre", {
                required: "Este campo es obligatorio",
                validate: {
                  lastChar: validateLastChar,
                },
              })}
            />
            <div className="invalid-feedback">{errors?.nombre?.message}</div>
          </div>
          <div className="col-3">
            <label htmlFor="agencia" className="form-label">
              Categoría
            </label>
            <select
              className={cx("form-select", errors?.categoria && "is-invalid")}
              id="categoria"
              defaultValue="0"
              {...register("categoria", { validate: validarSelect })}
            >
              <option disabled value="0">
                Selecciona una opción
              </option>
              {categorias.map(({ nombre, id }) => (
                <option key={id} value={nombre}>
                  {nombre}
                </option>
              ))}
            </select>
            <div className="invalid-feedback">Este campo es obligatorio</div>
          </div>
          <div className="col-3">
            <label htmlFor="agencia" className="form-label">
              Intermediario
            </label>
            <select
              className={cx("form-select", errors?.agencia && "is-invalid")}
              id="agencia"
              defaultValue="0"
              {...register("agencia")}
            >
              <option disabled value="0">
                Selecciona una opción
              </option>
              {intermediarios.map(({ nombre, id }) => (
                <option key={id} value={nombre}>
                  {nombre}
                </option>
              ))}
            </select>
            <div className="invalid-feedback">Este campo es obligatorio</div>
          </div>
          <div className="col-3">
            <label htmlFor="ejecutivo" className="form-label">
              Ejecutivo
            </label>
            <select
              className={cx("form-select", errors?.usuario && "is-invalid")}
              id="ejecutivo"
              defaultValue="0"
              {...register("ejecutivo", { validate: validarSelect })}
            >
              <option disabled value="0">
                Selecciona una opción
              </option>
              {usuarios.map(({ id, nombre }) => (
                <option key={id} value={id}>
                  {nombre}
                </option>
              ))}
            </select>
            <div className="invalid-feedback">Este campo es obligatorio</div>
          </div>
          <div className="col-12 mt-4 text-center">
            <button className="btn btn-primary" type="submit" disabled={disableSubmit}>
              {!disableSubmit ? (
                "GUARDAR"
              ) : (
                <>
                  <Spinner size="sm">GUARDANDO...</Spinner>
                  <span> GUARDANDO</span>
                </>
              )}
            </button>
          </div>
        </form>

        <Modal isOpen={showModalConfirmacion} toggle={() => setShowModalConfirmacion(false)}>
          <ModalHeader>¿Está seguro?</ModalHeader>
          <ModalBody>Se está por guardar un nuevo cliente</ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => setShowModalConfirmacion(false)}>
              Cancelar
            </Button>
            <Button color="primary" onClick={saveButtonHandle}>
              Guardar
            </Button>
          </ModalFooter>
        </Modal>
      </Container>
    </>
  );
};
