import { Route, Routes, Navigate } from "react-router-dom";
import { Loader, PrivateRoute, FichaEdificioInventario } from "./components";
import { ROLES } from "./constants/roles";
import { useAuthContext } from "./context/auth";
import {
  Admin,
  Api,
  CalculadorComisionEdificio,
  CargarCliente,
  CargarEdificio,
  CargarFactura,
  CargarIntermediario,
  CargarOportunidad,
  Categoria,
  CategoriaAutomotriz,
  CategoriaSmartphones,
  ClientesCRM,
  CotizadorProgrammatic,
  Edificios,
  Home,
  InventarioNuevo,
  LandingPeru,
  ListadoB2Bnuevo,
  ListadoCotizaciones,
  ListadoVetos,
  Login,
  Mapa,
  Mediakit,
  MediakitEdificios,
  MediakitEdificiosFullCbre,
  NotFound,
  Oportunidad,
  OportunidadFactura,
  Perfil,
  Portafolio,
  PortafolioFichaEdificios,
  PuntosDeVenta,
  Redirect,
  Usuarios,
  // VentasDashboardPage,
  VentasDetalle,
  VentasEjecutivo,
  VentasFactura,
  VentasProyeccion,
  VentasRegionReciente,
} from "./pages";
import MediakitEdificiosFull from "./pages/MediakitEdificiosFull";

const App = () => {
  const { session, pais } = useAuthContext();

  if (session === undefined) return <Loader fullPage />;

  const getPortfolioComponent = () => {
    if (pais === "Perú") {
      return <Portafolio />;
    } else if (pais === "Chile") {
      return <PortafolioFichaEdificios />;
    }
    return <NotFound />;
  };

  return (
    <Routes>
      <Route index element={<Home />} />
      <Route path="/bitcoinapi" element={<Api />} />
      <Route path="/categorias/automotriz" element={<CategoriaAutomotriz />} />
      <Route path="/categorias/smartphones" element={<CategoriaSmartphones />} />
      <Route path="/ficha-edificio/:id" element={<FichaEdificioInventario />} />
      <Route path="/inventario" element={<InventarioNuevo />} />
      <Route path="/landingperu" element={<LandingPeru />} />
      <Route path="/listadoempresas" element={<ListadoB2Bnuevo />} />
      <Route path="/listadovetos" element={<ListadoVetos />} />
      <Route path="/mapa" element={<Mapa />} />
      <Route path="/mediakit" element={<Mediakit />} />
      <Route path="/mediakit-edificios" element={<MediakitEdificios />} />
      <Route path="/mediakit-edificios-full" element={<MediakitEdificiosFull />} />
      <Route path="/mediakit-edificios-full-cbre" element={<MediakitEdificiosFullCbre />} />
      <Route path="/portafolio" element={getPortfolioComponent()} />
      <Route path="/portafolionuevo" element={<PortafolioFichaEdificios />} />
      <Route path="/puntosdeventa" element={<PuntosDeVenta />} />
      <Route path="/redirect" element={<Redirect />} />

      {session !== null ? (
        <>
          <Route
            path="/admin"
            element={
              <PrivateRoute
                allowedRoles={[
                  ROLES.SUPERADMIN,
                  ROLES.GERENCIA,
                  ROLES.COMERCIAL,
                  ROLES.ADMINISTRATIVO,
                  ROLES.EDIFICIO,
                  ROLES.AGENCIA,
                ]}
              >
                <Admin />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/cargar-cliente"
            element={
              <PrivateRoute allowedRoles={[ROLES.SUPERADMIN, ROLES.GERENCIA, ROLES.COMERCIAL]}>
                <CargarCliente />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/cargar-factura"
            element={
              <PrivateRoute allowedRoles={[ROLES.SUPERADMIN, ROLES.GERENCIA, ROLES.COMERCIAL]}>
                <CargarFactura />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/cargar-intermediario"
            element={
              <PrivateRoute allowedRoles={[ROLES.SUPERADMIN, ROLES.GERENCIA, ROLES.COMERCIAL]}>
                <CargarIntermediario />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/cargar-oportunidad"
            element={
              <PrivateRoute allowedRoles={[ROLES.SUPERADMIN, ROLES.GERENCIA, ROLES.COMERCIAL]}>
                <CargarOportunidad />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/cargar-edificio"
            element={
              <PrivateRoute allowedRoles={[ROLES.SUPERADMIN, ROLES.ADMINISTRATIVO]}>
                <CargarEdificio />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/factura/:oportunidadId"
            element={
              <PrivateRoute allowedRoles={[ROLES.SUPERADMIN, ROLES.COMERCIAL, ROLES.ADMINISTRATIVO]}>
                <OportunidadFactura />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/informes-comision"
            element={
              <PrivateRoute allowedRoles={[ROLES.SUPERADMIN, ROLES.GERENCIA, ROLES.ADMINISTRATIVO, ROLES.EDIFICIO]}>
                <CalculadorComisionEdificio />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/online-cotizador"
            element={
              <PrivateRoute allowedRoles={[ROLES.SUPERADMIN, ROLES.GERENCIA, ROLES.COMERCIAL, ROLES.AGENCIA]}>
                <CotizadorProgrammatic />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/oportunidad/:oportunidadId"
            element={
              <PrivateRoute allowedRoles={[ROLES.SUPERADMIN, ROLES.GERENCIA, ROLES.COMERCIAL, ROLES.ADMINISTRATIVO]}>
                <Oportunidad />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/perfil"
            element={
              <PrivateRoute allowedRoles={[ROLES.SUPERADMIN]}>
                <Perfil />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/usuarios"
            element={
              <PrivateRoute allowedRoles={[ROLES.SUPERADMIN, ROLES.GERENCIA, ROLES.COMERCIAL, ROLES.ADMINISTRATIVO]}>
                <Usuarios />
              </PrivateRoute>
            }
          />
          {/* <Route
            path="/admin/ventas-dashboard"
            element={
              <PrivateRoute allowedRoles={[ROLES.SUPERADMIN, ROLES.GERENCIA, ROLES.COMERCIAL, ROLES.ADMINISTRATIVO]}>
                <VentasDashboardPage />
              </PrivateRoute>
            }
          /> */}
          <Route
            path="/admin/ventas-detalle"
            element={
              <PrivateRoute allowedRoles={[ROLES.SUPERADMIN, ROLES.GERENCIA, ROLES.COMERCIAL, ROLES.ADMINISTRATIVO]}>
                <VentasDetalle />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/ventas-facturas"
            element={
              <PrivateRoute allowedRoles={[ROLES.SUPERADMIN, ROLES.GERENCIA, ROLES.COMERCIAL, ROLES.ADMINISTRATIVO]}>
                <VentasFactura />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/ventas-proyeccion"
            element={
              <PrivateRoute allowedRoles={[ROLES.SUPERADMIN, ROLES.GERENCIA, ROLES.COMERCIAL, ROLES.ADMINISTRATIVO]}>
                <VentasProyeccion />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/mis-ventas"
            element={
              <PrivateRoute allowedRoles={[ROLES.SUPERADMIN, ROLES.GERENCIA, ROLES.COMERCIAL]}>
                <VentasEjecutivo />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/ventas-region"
            element={
              <PrivateRoute allowedRoles={[ROLES.SUPERADMIN, ROLES.GERENCIA, ROLES.COMERCIAL]}>
                <VentasRegionReciente />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/edificios"
            element={
              <PrivateRoute allowedRoles={[ROLES.SUPERADMIN, ROLES.GERENCIA, ROLES.ADMINISTRATIVO]}>
                <Edificios />
              </PrivateRoute>
            }
          />
          <Route
            path="/categorias"
            element={
              <PrivateRoute allowedRoles={[ROLES.SUPERADMIN, ROLES.GERENCIA, ROLES.COMERCIAL, ROLES.AGENCIA]}>
                <Categoria />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/cotizaciones"
            element={
              <PrivateRoute allowedRoles={[ROLES.SUPERADMIN]}>
                <ListadoCotizaciones />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/clientes"
            element={
              <PrivateRoute allowedRoles={[ROLES.SUPERADMIN]}>
                <ClientesCRM />
              </PrivateRoute>
            }
          />
          <Route path="/login" element={<Navigate to="/admin" />} />
        </>
      ) : (
        <>
          <Route path="*" element={<Navigate to="/login" />} />
          <Route path="/login" element={<Login />} />
        </>
      )}

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default App;
