/* eslint-disable no-console */
import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { Container, ListadoFacturas } from "../../components";
import { useAuthContext } from "../../context/auth";
import {
  getEdificiosOperativos,
  getFacturasComision2,
  getFacturasComisionTodos,
  getIntermediarios,
  log,
} from "../../utils";
import "./styles.scss";

export const CalculadorComisionEdificio = () => {
  const [edificios, setEdificios] = useState([]);
  const [facturas, setFacturas] = useState(null);
  const [todosEdificios, setTodosEdificios] = useState([]);
  const [intermediarios, setIntermediarios] = useState([]);

  const { pais, edificiosOk } = useAuthContext() || {};

  const { handleSubmit, register, watch } = useForm({ defaultValues: { edificio: "0" } });

  const [edificioSeleccionado, fechaInicio, fechaFin] = watch(["edificio", "fechaInicio", "fechaFin"]);

  const regexFecha = useMemo(() => /^(20\d{2})-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$/, []);

  const localString = useCallback(
    (pais) =>
      ({
        Chile: "es-CL",
        Perú: "es-PE",
        Uruguay: "es-UY",
      })[pais] || "es-UY",
    []
  );

  const moneda = useCallback(
    (pais) =>
      ({
        Chile: "$ ",
        Perú: "S/ ",
        Uruguay: "$ ",
      })[pais] || "$ ",
    []
  );

  const decimales = useCallback(
    (pais) =>
      ({
        Chile: 0,
        Perú: 2,
        Uruguay: 0,
      })[pais] || 0,
    []
  );

  useEffect(() => {
    const fetchIntermediarios = async () => {
      const intermediariosData = await getIntermediarios();
      setIntermediarios(intermediariosData);
    };
    fetchIntermediarios();
  }, []);

  const calcularNetoConIntermediario = (factura, intermediarios, pais) => {
    // Buscar intermediario tanto por nombre como por país
    const intermediario = intermediarios.find((inter) => inter.nombre === factura.intermediario && inter.pais === pais);
    const porcentajeComision = intermediario ? intermediario.comision : 0;
    const neto = factura.importe - (factura.importe * porcentajeComision) / 100;
    return neto;
  };

  const onSubmit = useCallback(
    async (data) => {
      try {
        setFacturas(null);

        let listaFacturas = [];

        if (data.edificio === "todos") {
          // Consultar facturas para todos los edificios sin filtrar por alianza
          listaFacturas = await getFacturasComisionTodos({
            edificios: todosEdificios.map((edificio) => edificio.id),
            fechas: [data.fechaInicio, data.fechaFin],
          });

          if (listaFacturas.length === 0) {
            setFacturas([]); // Si no hay facturas, mostramos una lista vacía
            return;
          }

          // Cálculo de comisiones para todos los edificios seleccionados
          const resumenComisionesTodos = todosEdificios.map((edificio) => {
            const facturasEdificio = listaFacturas.filter((factura) =>
              factura.edificiosparticipantes.includes(edificio.id.toString())
            );

            const totalFacturado = facturasEdificio.reduce((total, factura) => {
              // Obtener la suma total de pantallas de los edificios participantes en esta factura
              const totalPantallasFactura = todosEdificios.reduce(
                (sum, ed) =>
                  factura.edificiosparticipantes.includes(ed.id.toString())
                    ? sum + (ed.pantallas + ed.totems + ed.espera + ed.cowork)
                    : sum,
                0
              );

              // Calcular la proporción del edificio actual sobre el total de pantallas
              const proporcionPantallas =
                totalPantallasFactura > 0
                  ? (edificio.pantallas + edificio.totems + edificio.espera + edificio.cowork) / totalPantallasFactura
                  : 0;

              return total + proporcionPantallas * (factura.importe || 0);
            }, 0);

            const netoFacturado = facturasEdificio.reduce((total, factura) => {
              // Obtener la suma total de pantallas de los edificios participantes en esta factura
              const totalPantallasFactura = todosEdificios.reduce(
                (sum, ed) =>
                  factura.edificiosparticipantes.includes(ed.id.toString())
                    ? sum + (ed.pantallas + ed.totems + ed.espera + ed.cowork)
                    : sum,
                0
              );

              // Calcular la proporción del edificio actual sobre el total de pantallas
              const proporcionPantallas =
                totalPantallasFactura > 0
                  ? (edificio.pantallas + edificio.totems + edificio.espera + edificio.cowork) / totalPantallasFactura
                  : 0;

              // Calcular el neto considerando el intermediario
              const neto = calcularNetoConIntermediario(factura, intermediarios, pais);

              return total + proporcionPantallas * neto;
            }, 0);

            const comisionGenerada = facturasEdificio.reduce((total, factura) => {
              const proporcionPantallas =
                factura.pantallastotales > 0
                  ? (edificio.pantallas + edificio.totems + edificio.espera + edificio.cowork) /
                    factura.pantallastotales
                  : 0;

              const neto = calcularNetoConIntermediario(factura, intermediarios, pais);

              const porcentajeComision =
                factura.facturafecha >= edificio.comision_1_fechainicio &&
                factura.facturafecha <= edificio.comision_1_fechafin
                  ? edificio.comision_1_porcentaje
                  : factura.facturafecha >= edificio.comision_2_fechainicio &&
                      factura.facturafecha <= edificio.comision_2_fechafin
                    ? edificio.comision_2_porcentaje
                    : edificio.comision_3_porcentaje;

              return total + proporcionPantallas * (neto * (porcentajeComision / 100));
            }, 0);

            return {
              edificio: edificio.edificio,
              totalFacturado,
              netoFacturado,
              comisionGenerada,
            };
          });

          setFacturas(resumenComisionesTodos);
        } else if (todosEdificios.some((edificio) => edificio.alianza === data.edificio)) {
          // Verificar si se seleccionó una alianza
          const alianzaSeleccionada = data.edificio;

          // Agrupar edificios por la alianza seleccionada
          const edificiosAlianza = todosEdificios.filter((edificio) => edificio.alianza === alianzaSeleccionada);

          listaFacturas = await getFacturasComisionTodos({
            edificios: edificiosAlianza.map((edificio) => edificio.id),
            fechas: [data.fechaInicio, data.fechaFin],
          });

          if (listaFacturas.length === 0) {
            setFacturas([]); // Si no hay facturas, mostramos una lista vacía
            return;
          }

          // Cálculo de comisiones para la alianza seleccionada
          const resumenComisionesAlianza = edificiosAlianza.map((edificio) => {
            const facturasEdificio = listaFacturas.filter((factura) =>
              factura.edificiosparticipantes.includes(edificio.id.toString())
            );

            const totalFacturado = facturasEdificio.reduce((total, factura) => {
              const proporcionPantallas =
                factura.pantallastotales > 0
                  ? (edificio.pantallas + edificio.totems + edificio.espera + edificio.cowork) /
                    factura.pantallastotales
                  : 0;
              return total + proporcionPantallas * (factura.importe || 0);
            }, 0);

            const netoFacturado = facturasEdificio.reduce((total, factura) => {
              const proporcionPantallas =
                factura.pantallastotales > 0
                  ? (edificio.pantallas + edificio.totems + edificio.espera + edificio.cowork) /
                    factura.pantallastotales
                  : 0;
              const neto = calcularNetoConIntermediario(factura, intermediarios, pais);
              return total + proporcionPantallas * neto;
            }, 0);

            const comisionGenerada = facturasEdificio.reduce((total, factura) => {
              const proporcionPantallas =
                factura.pantallastotales > 0
                  ? (edificio.pantallas + edificio.totems + edificio.espera + edificio.cowork) /
                    factura.pantallastotales
                  : 0;

              const neto = calcularNetoConIntermediario(factura, intermediarios, pais);

              const porcentajeComision =
                factura.facturafecha >= edificio.comision_1_fechainicio &&
                factura.facturafecha <= edificio.comision_1_fechafin
                  ? edificio.comision_1_porcentaje
                  : factura.facturafecha >= edificio.comision_2_fechainicio &&
                      factura.facturafecha <= edificio.comision_2_fechafin
                    ? edificio.comision_2_porcentaje
                    : edificio.comision_3_porcentaje;

              return total + proporcionPantallas * (neto * (porcentajeComision / 100));
            }, 0);

            return {
              edificio: edificio.edificio,
              totalFacturado,
              netoFacturado,
              comisionGenerada,
            };
          });

          setFacturas(resumenComisionesAlianza);
        } else {
          // Lógica que ya funciona para un solo edificio
          const edificio = todosEdificios.find((ed) => ed.id === Number(data.edificio));
          listaFacturas = await getFacturasComision2({
            edificio: data.edificio,
            fechas: [data.fechaInicio, data.fechaFin],
          });

          if (listaFacturas.length === 0) {
            setFacturas([]);
            return;
          }

          const newFacturas = {
            comisionEdificio_1_porcentaje: edificio.comision_1_porcentaje,
            comisionEdificio_1_fechainicio: edificio.comision_1_fechainicio,
            comisionEdificio_1_fechafin: edificio.comision_1_fechafin,
            comisionEdificio_2_porcentaje: edificio.comision_2_porcentaje,
            comisionEdificio_2_fechainicio: edificio.comision_2_fechainicio,
            comisionEdificio_2_fechafin: edificio.comision_2_fechafin,
            comisionEdificio_3_porcentaje: edificio.comision_3_porcentaje,
            comisionEdificio_3_fechainicio: edificio.comision_3_fechainicio,
            comisionEdificio_3_fechafin: edificio.comision_3_fechafin,
            listado: listaFacturas.map((factura) => {
              const participantes = factura.edificiosparticipantes
                ? factura.edificiosparticipantes.split(",").map((id) => id.trim())
                : [];

              const pantallasTotales = participantes.reduce((total, id) => {
                const edificio = todosEdificios.find((ed) => ed.id.toString() === id);
                return (
                  total + (edificio ? edificio.pantallas + edificio.totems + edificio.espera + edificio.cowork : 0)
                );
              }, 0);

              return {
                ...factura,
                edificiosUnicos: participantes.length,
                pantallasTotales,
              };
            }),
            pantallasEdificio: edificio.pantallas + edificio.totems + edificio.espera + edificio.cowork,
          };

          setFacturas(newFacturas);
        }
      } catch (error) {
        console.error("Error en onSubmit:", error);
        log.error("CalculadorComisionEdificio", error);
      }
    },
    [edificios, todosEdificios, intermediarios, pais]
  );

  const forceSubmit = useCallback(() => handleSubmit(onSubmit)(), [handleSubmit, onSubmit]);

  useEffect(() => {
    const initialize = async () => {
      const newEdificios = await getEdificiosOperativos({ pais });
      newEdificios.sort((a, b) => a.edificio.localeCompare(b.edificio));
      setEdificios(newEdificios);

      const todos = !edificiosOk
        ? newEdificios // Si edificiosOk es NULL, todos los edificios disponibles
        : newEdificios.filter((edificio) => edificiosOk.includes(edificio.id));
      setTodosEdificios(todos);
    };

    initialize();
  }, [pais, edificiosOk]);

  useEffect(() => {
    if (edificioSeleccionado !== "0" && regexFecha.test(fechaInicio) && regexFecha.test(fechaFin)) forceSubmit();
  }, [forceSubmit, regexFecha, edificioSeleccionado, fechaInicio, fechaFin]);

  return (
    <>
      <Container className="CalculadorComisionEdificio" header titulo="COMISIÓN EDIFICIOS">
        <form className="row g-3 mb-5" onSubmit={handleSubmit(onSubmit)}>
          <div className="col-4">
            <div className="form-floating">
              <select className="form-select" {...register("edificio")}>
                <option disabled value="0">
                  Selecciona una opción
                </option>
                {todosEdificios.length > 1 && <option value="todos">Todos</option>}
                {/* Opciones por alianza */}
                {[...new Set(todosEdificios.map((edificio) => edificio.alianza))]
                  .filter((alianza) => alianza)
                  .map((alianza) => (
                    <option key={alianza} value={alianza}>
                      {`${alianza}`}
                    </option>
                  ))}
                {/* Opciones individuales */}
                {todosEdificios.map((edificio) => (
                  <option key={edificio.id} value={edificio.id}>
                    {edificio.edificio}
                  </option>
                ))}
              </select>
              <label>1. Edificio o Alianza</label>
            </div>
          </div>
          <div className="col-4">
            <div className="form-floating">
              <input type="date" className="form-control" {...register("fechaInicio")} />
              <label>2. Periodo de consulta - Fecha de Inicio</label>
            </div>
          </div>
          <div className="col-4">
            <div className="form-floating">
              <input type="date" className="form-control" {...register("fechaFin")} />
              <label>3. Periodo de consulta - Fecha de Fin</label>
            </div>
          </div>
        </form>

        {/* Si seleccionamos un solo edificio, usamos la lógica anterior */}
        {facturas && facturas.listado && facturas.listado.length > 0 && (
          <ListadoFacturas
            comisionEdificio_1_porcentaje={facturas.comisionEdificio_1_porcentaje}
            comisionEdificio_1_fechainicio={facturas.comisionEdificio_1_fechainicio}
            comisionEdificio_1_fechafin={facturas.comisionEdificio_1_fechafin}
            comisionEdificio_2_porcentaje={facturas.comisionEdificio_2_porcentaje}
            comisionEdificio_2_fechainicio={facturas.comisionEdificio_2_fechainicio}
            comisionEdificio_2_fechafin={facturas.comisionEdificio_2_fechafin}
            comisionEdificio_3_porcentaje={facturas.comisionEdificio_3_porcentaje}
            comisionEdificio_3_fechainicio={facturas.comisionEdificio_3_fechainicio}
            comisionEdificio_3_fechafin={facturas.comisionEdificio_3_fechafin}
            facturas={facturas.listado}
            pantallasEdificio={facturas.pantallasEdificio}
            pais={pais}
          />
        )}

        {/* Si seleccionamos "Todos", mostramos el resumen de comisiones */}
        {facturas && facturas.length > 0 && (
          <div>
            <table className="table table-fixed table-striped table-bordered table-hover table-custom">
              <thead className="text-center align-middle">
                <tr className="total-row table-success">
                  <td className="text-start" colSpan={2}>
                    TOTALES
                  </td>
                  <td className="text-end">
                    {`${moneda(pais)} ${facturas
                      .reduce((total, resumen) => total + resumen.totalFacturado, 0)
                      .toLocaleString(localString(pais), {
                        minimumFractionDigits: decimales(pais),
                        maximumFractionDigits: decimales(pais),
                      })}`}
                  </td>
                  <td className="text-end">
                    {`${moneda(pais)} ${facturas
                      .reduce((total, resumen) => total + resumen.netoFacturado, 0)
                      .toLocaleString(localString(pais), {
                        minimumFractionDigits: decimales(pais),
                        maximumFractionDigits: decimales(pais),
                      })}`}
                  </td>
                  <td className="text-end">
                    {`${moneda(pais)} ${facturas
                      .reduce((total, resumen) => total + resumen.comisionGenerada, 0)
                      .toLocaleString(localString(pais), {
                        minimumFractionDigits: decimales(pais),
                        maximumFractionDigits: decimales(pais),
                      })}`}
                  </td>
                  <td className="text-center">
                    {facturas
                      .reduce((total, resumen) => {
                        const totalComisionGenerada = facturas.reduce(
                          (total, item) => total + item.comisionGenerada,
                          0
                        );
                        const porcentajeComision = totalComisionGenerada
                          ? (resumen.comisionGenerada / totalComisionGenerada) * 100
                          : 0;
                        return total + porcentajeComision;
                      }, 0)
                      .toFixed(0)}
                    %
                  </td>
                </tr>
                <tr className="table-dark">
                  <th style={{ width: "5%" }}>#</th>
                  <th style={{ width: "25%" }}>EDIFICIO</th>
                  <th style={{ width: "20%" }}>FACTURACIÓN BRUTA</th>
                  <th style={{ width: "20%" }}>FACTURACIÓN NETA</th>
                  <th style={{ width: "20%" }}>COMISIÓN GENERADA</th>
                  <th style={{ width: "10%" }}>%</th>
                </tr>
              </thead>
              <tbody>
                {facturas.map((resumen, index) => {
                  const totalComisionGenerada = facturas.reduce((total, item) => total + item.comisionGenerada, 0);
                  const porcentajeComision = totalComisionGenerada
                    ? (resumen.comisionGenerada / totalComisionGenerada) * 100
                    : 0;

                  return (
                    <tr key={resumen.edificio}>
                      <td className="text-center" style={{ width: "5%" }}>
                        {index + 1}
                      </td>
                      <td className="text-start uppercase">{resumen.edificio}</td>
                      <td className="text-end">{`${moneda(pais)} ${resumen.totalFacturado.toLocaleString(
                        localString(pais),
                        {
                          minimumFractionDigits: decimales(pais),
                          maximumFractionDigits: decimales(pais),
                        }
                      )}`}</td>
                      <td className="text-end">{`${moneda(pais)} ${resumen.netoFacturado.toLocaleString(
                        localString(pais),
                        {
                          minimumFractionDigits: decimales(pais),
                          maximumFractionDigits: decimales(pais),
                        }
                      )}`}</td>
                      <td className="text-end">{`${moneda(pais)} ${resumen.comisionGenerada.toLocaleString(
                        localString(pais),
                        {
                          minimumFractionDigits: decimales(pais),
                          maximumFractionDigits: decimales(pais),
                        }
                      )}`}</td>
                      <td className="text-center">{porcentajeComision.toFixed(1)}%</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </Container>
    </>
  );
};
