/* eslint-disable no-console */
import toast from "react-hot-toast";
import { log, supabase } from ".";

export const getOportunidades = async ({ pais, ano, orderBy = "mes", ascending = true }) => {
  try {
    const data = await supabase.select("sales", {
      filters: {
        pais,
        ano: parseInt(ano),
      },
      orderBy,
      ascending,
    });

    if (data?.length === 0) {
      toast.error("No hay oportunidades cargadas en la base de datos");
      throw new Error("No data found");
    }
    return data;
  } catch (error) {
    log.error("getOportunidades", error);
    throw error;
  }
};
