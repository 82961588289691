import React, { useState } from "react";
import { Container, ListaClientesMisVentas, VentasTablaEjecutivo } from "../../components";
import "./styles.scss";

const VentasEjecutivo = () => {
  const [showTable, setShowTable] = useState(false);

  const toggleTableVisibility = () => {
    setShowTable(!showTable);
  };

  return (
    <div>
      <Container header titulo="MIS VENTAS">
        <div className="row mb-3">
          <div className="col-md-2 offset-md-5">
            <button onClick={toggleTableVisibility} className="btn btn-dark w-100">
              {showTable ? "Ocultar Proyección" : "Mostrar Proyección"}
            </button>
          </div>
        </div>
        {showTable && <VentasTablaEjecutivo />}
        <ListaClientesMisVentas />
      </Container>
    </div>
  );
};

export default VentasEjecutivo;
