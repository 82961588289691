/* eslint-disable no-console */
import { useState } from "react";
import { Container, VentasTabla, VentasTop5 } from "../../components";

const VentasProyeccion = () => {
  const [filtroAno, setFiltroAno] = useState("2025");

  return (
    <div>
      <Container header titulo="PROYECCIÓN COMERCIAL">
        <VentasTabla filtroAno={filtroAno} setFiltroAno={setFiltroAno} />
        <VentasTop5 filtroAno={filtroAno} />
      </Container>
    </div>
  );
};

export default VentasProyeccion;
