/* eslint-disable indent */
/* eslint-disable no-console */
import React, { useEffect, useState, useCallback } from "react";
import { BarChart, Bar, YAxis, ResponsiveContainer, Tooltip, XAxis, LabelList } from "recharts";
import { useAuthContext } from "../../context/auth";
import { getOportunidades } from "../../utils";

export const VentasTop5 = ({ filtroAno }) => {
  const [oportunidades, setOportunidades] = useState([]);
  const { pais } = useAuthContext();

  const localString = useCallback(
    (pais) =>
      ({
        Chile: "es-CL",
        Perú: "es-PE",
        Uruguay: "es-UY",
      })[pais] || "es-UY",
    []
  );

  const moneda = useCallback(
    (pais) =>
      ({
        Chile: "$ ",
        Perú: "S/ ",
        Uruguay: "$ ",
      })[pais] || "$ ",
    []
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const newOportunidades = await getOportunidades({ pais });
        const filteredOportunidades = newOportunidades.filter(
          (oportunidad) => oportunidad.estatus === "100%" && oportunidad.ano === parseInt(filtroAno)
        );
        setOportunidades(filteredOportunidades);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [pais, filtroAno]);

  const calculateAndSortData = (data, key) => {
    const grouped = data.reduce((acc, item) => {
      const identifier = item[key];
      acc[identifier] = (acc[identifier] || 0) + item.importe;
      return acc;
    }, {});
    return Object.entries(grouped)
      .map(([name, value]) => ({ name, value }))
      .sort((a, b) => b.value - a.value); // Ordenar de mayor a menor
  };

  const clientesData = calculateAndSortData(oportunidades, "cliente");
  const agenciasData = calculateAndSortData(oportunidades, "intermediario");
  const categoriasData = calculateAndSortData(oportunidades, "categoria");

  const chartStyle = {
    fontFamily: "Trebuchet MS",
    fontSize: "10px",
  };

  const colors = {
    cliente: "#333333",
    intermediario: "#333333",
    categoria: "#333333",
  };

  const getTextWidth = (text, fontSize, font) => {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    context.font = `${fontSize} ${font}`;
    return context.measureText(text).width;
  };

  const customTick = ({ x, y, payload }) => {
    const text = payload.value;
    const lineHeight = 12;
    const maxWidth = 150;
    const words = text.split(" ");
    let line = "";
    const lines = [];

    for (let n = 0; n < words.length; n++) {
      const testLine = line + words[n] + " ";
      const testWidth = getTextWidth(testLine, chartStyle.fontSize, chartStyle.fontFamily);
      if (testWidth > maxWidth && n > 0) {
        lines.push(line.trim());
        line = words[n] + " ";
      } else {
        line = testLine;
      }
    }
    lines.push(line.trim());

    // Ajustar las coordenadas para etiquetas horizontales
    const dy = y + (lines.length * lineHeight) / 2 - lineHeight;

    return (
      <g transform={`translate(${x},${dy})`}>
        {lines.map((lineText, index) => (
          <text key={index} x={0} y={index * lineHeight} textAnchor="start" style={{ ...chartStyle, fill: "black" }}>
            {lineText}
          </text>
        ))}
      </g>
    );
  };

  const renderCustomLabel = (props) => {
    const { x, y, width, height, value, name } = props;
    if (value === undefined || typeof value !== "number") {
      console.warn("Datos incompletos o incorrectos para el label:", value);
      return null;
    }
    const text = `${name} ${moneda(pais)}${value.toLocaleString(localString(pais), { minimumFractionDigits: 0, maximumFractionDigits: 0 })}`;

    const adjustedX = x + 50;
    const barEnd = x + width;

    // Función para decidir el color del texto basado en la posición
    const getTextColor = (charX) => (charX < barEnd ? "white" : "black");

    // Ajuste para centrar verticalmente el texto dentro de la barra
    const textHeight = 12; // Asumiendo que la altura del texto es igual al tamaño de la fuente
    const dy = (height - textHeight) / 3 + textHeight * 0.05; // Ajuste adicional para alineación vertical precisa

    return (
      <g>
        {[...text].map((char, index) => {
          const prevCharsWidth = getTextWidth(text.slice(0, index), "12px", "Trebuchet MS");
          const charX = adjustedX + prevCharsWidth;
          const charWidth = getTextWidth(char, "12px", "Trebuchet MS");

          return (
            <text
              key={index}
              x={charX}
              y={y + height / 2}
              dy={dy}
              fontSize="12px"
              fill={getTextColor(charX + charWidth / 2)}
            >
              {char}
            </text>
          );
        })}
      </g>
    );
  };

  return (
    <div style={{ display: "flex", justifyContent: "space-between", padding: "10px 0", marginBottom: "20px" }}>
      {/* Clientes */}
      <div style={{ width: "33%", marginRight: "10px" }}>
        <h5 className="bg-dark text-white p-1">CLIENTES</h5>
        <ResponsiveContainer width="100%" height={clientesData.length * 40}>
          <BarChart layout="vertical" data={clientesData} margin={{ left: -40, right: 0, top: 0, bottom: 0 }}>
            <XAxis type="number" hide={true} />
            <YAxis
              type="category"
              dataKey="name"
              width={60}
              interval={0}
              tickSize={0}
              tick={customTick}
              axisLine={{ x: 0 }}
              hide={true}
            />
            <Tooltip
              formatter={(value) =>
                `${moneda(pais)}${value.toLocaleString(localString(pais), { minimumFractionDigits: 0, maximumFractionDigits: 0 })}`
              }
            />
            <Bar dataKey="value" fill={colors.cliente} barSize={25}>
              <LabelList content={renderCustomLabel} />
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>

      {/* Agencias */}
      <div style={{ width: "33%", marginRight: "10px" }}>
        <h5 className="bg-dark text-white p-1">AGENCIAS</h5>
        <ResponsiveContainer width="100%" height={agenciasData.length * 40}>
          <BarChart layout="vertical" data={agenciasData} margin={{ left: -40, right: 0, top: 0, bottom: 0 }}>
            <XAxis type="number" hide={true} />
            <YAxis
              type="category"
              dataKey="name"
              width={60}
              interval={0}
              tickSize={0}
              tick={customTick}
              axisLine={{ x: 0 }}
              hide={true}
            />
            <Tooltip
              formatter={(value) =>
                `${moneda(pais)}${value.toLocaleString(localString(pais), { minimumFractionDigits: 0, maximumFractionDigits: 0 })}`
              }
            />
            <Bar dataKey="value" fill={colors.intermediario} barSize={25}>
              <LabelList content={renderCustomLabel} />
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>

      {/* Categorías */}
      <div style={{ width: "33%" }}>
        <h5 className="bg-dark text-white p-1">CATEGORÍAS</h5>
        <ResponsiveContainer width="100%" height={categoriasData.length * 40}>
          <BarChart layout="vertical" data={categoriasData} margin={{ left: -40, right: 0, top: 0, bottom: 0 }}>
            <XAxis type="number" hide={true} />
            <YAxis
              type="category"
              dataKey="name"
              width={60}
              interval={0}
              tickSize={0}
              tick={customTick}
              axisLine={{ x: 0 }}
              hide={true}
            />
            <Tooltip
              formatter={(value) =>
                `${moneda(pais)}${value.toLocaleString(localString(pais), { minimumFractionDigits: 0, maximumFractionDigits: 0 })}`
              }
            />
            <Bar dataKey="value" fill={colors.categoria} barSize={25}>
              <LabelList content={renderCustomLabel} />
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};
